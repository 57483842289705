import Crehana from '../icon/crehana.svg'
import CrehanaBW from '../icon/crehana-bw.svg'
import Google from '../icon/google.svg'
import GoogleBW from '../icon/google-bw.svg'
import BancAprende from '../icon/bancaprende.svg'
import BancAprendeBW from '../icon/bancaprende-bw.svg'
import Banconsejos from '../icon/banconsejos.svg'
import BanconsejosBW from '../icon/banconsejos-bw.svg'
import LaChiripa from '../icon/lachiripa-color.png'
import LaChiripaBW from '../icon/lachiripa-bw.png'

export const PROVIDERS = [
  'crehana',
  'google',
  'bancaaprende',
  'banconsejos',
  'lachiripa'
]

export const LACHIRIPA_TYPES = [
  'serie',
  'capsule'
]

export const PROVIDERS_COLOR_MAP = {
  crehana: 'green-500',
  google: 'red-500',
  bancaaprende: 'turquoise-600',
  banconsejos: 'yellow-500',
  lachiripa: 'green-500'
}

export const PROVIDERS_TEXT_MAP = {
  crehana: 'Desarrollo laboral por',
  google: 'Herramientas de productividad por',
  bancaaprende: 'Educación financiera por',
  banconsejos: 'Asesoría financiera por',
  lachiripa: 'Educación infantil por'
}

export const PROVIDERS_ICON_MAP = {
  crehana: Crehana,
  google: Google,
  bancaaprende: BancAprende,
  banconsejos: Banconsejos,
  lachiripa: LaChiripa
}

export const PROVIDERS_BWICON_MAP = {
  crehana: CrehanaBW,
  google: GoogleBW,
  bancaaprende: BancAprendeBW,
  banconsejos: BanconsejosBW,
  lachiripa: LaChiripaBW
}
