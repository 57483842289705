import PropTypes from 'prop-types'
import classNames from 'classnames'
import { get } from 'lodash'

import Icon from '../../../icon'
import Typography from '../../../typography'

import Users from '../../../icon/users.svg'
import Star from '../../../icon/star.svg'
import NewIcon from '../../../icon/new_icon.svg'
import moment from 'moment'

const Stats = ({ course, ...attrs }) => {
  const subscriptions = get(course, 'subscriptions', 0)
  const likes = get(course, 'likes', 0)
  const publishedDate = get(course, 'meta.firstPublishedAt', '')
  const isNew = moment().diff(moment(publishedDate), 'months') < 1

  return <div
    className={
      classNames([
        'flex',
        'flex-row',
        'items-center'
      ])
    }>
    <div
      className={
        classNames([
          'flex',
          'flex-row',
          'justify-center',
          'items-center',
          'mr-10'
        ])
      }>
      <Icon
        className={'mr-2 text-white'}
        svg={Users}
        width={24}
        height={24} />
      <Typography
        className={'text-white'}>
        {subscriptions}
      </Typography>
    </div>
    <div
      className={
        classNames([
          'flex',
          'flex-row',
          'justify-center',
          'items-center'
        ])
      }>
      <Icon
        className={'mr-2 text-yellow-500'}
        svg={Star}
        width={24}
        height={24} />
      <Typography
        className={'text-white'}>
        {likes}
      </Typography>
      {isNew && (
        <div className={'absolute right-5'} style={{ width: '60px', height: '30px' }}>
          <NewIcon style={{ width: '100%', height: '100%' }} />
        </div>
      )}

    </div>
  </div>
}

Stats.propTypes = {
  course: PropTypes.object
}

export default Stats
