import PropTypes from 'prop-types'
import classNames from 'classnames'
import { get } from 'lodash'

import Stats from './stats'
import PreviewTrigger from '../../preview/trigger'

const Media = ({ course, integrationSettings, ...attrs }) => {
  const backgroundImageUrl = get(course, 'headerImageListStandar.url', null)
  const hasPreview = get(course, 'previewVideo', null) || get(course, 'previewEmbed', null)
  return <div
    className={
      classNames([
        'relative',
        'bg-no-repeat',
        'bg-center',
        'bg-cover',
        'p-6'
      ])
    }
    style={{
      height: '12rem',
      borderRadius: '0 2.5rem 2.5rem 2.5rem',
      backgroundImage: backgroundImageUrl ? `url('${backgroundImageUrl}')` : null
    }}>
    <Stats course={course} />
    {
      hasPreview && course.provider !== 'lachiripa'
        ? <PreviewTrigger
          course={course}
          integrationSettings={integrationSettings}
          className={
            classNames([
              'absolute',
              'top-1/2',
              'left-1/2',
              'transform',
              '-translate-x-1/2',
              '-translate-y-1/2'
            ])
          } />
        : null
    }
  </div>
}

Media.propTypes = {
  course: PropTypes.object,
  integrationSettings: PropTypes.object
}

export default Media
