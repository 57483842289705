import PropTypes from 'prop-types'
import Link from 'next/link'

import Typography from '../../typography'
import { getAuthor } from '../utils'

const Body = ({ course, ...attrs }) => {
  const author = getAuthor(course)
  const courseUrl = course.provider === 'lachiripa'
    ? course.lachiripaCourseUrl
    : `/cursos/${course.id}/${course.meta.slug}`

  return <div
    className={'p-4'}>
    <Link
      href={courseUrl}>
      <a
        className={'block w-full mb-3'}
        style={{
          borderRadius: '0 1.5rem 1.5rem 1.5rem'
        }}
        target={course.provider === 'lachiripa' ? '_blank' : '_self'}>

        <Typography
          size={'four'}
          weight={'black'}
          clamp={2}
          style={{
            minHeight: '56px'
          }}>
          {course.title}
        </Typography>
      </a>
    </Link>
    <Typography
      size={'three'}
      weight={'regular'}>
      Por{' '}
      <Typography
        tag={'span'}
        size={'three'}
        weight={'medium'}>
        {author && author.fullName ? author.fullName : 'El equipo Crehana'}
      </Typography>
    </Typography>
  </div>
}

Body.propTypes = {
  course: PropTypes.object
}

export default Body
