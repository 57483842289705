import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from 'next/link'

import { useBreakpoint } from '../../../../hooks/breakpoint'

import Media from './media'
import Body from './body'
import Action from '../action'

const Card = ({ course, integrationSettings, ...attrs }) => {
  const breakpoint = useBreakpoint()

  attrs.className = classNames(
    attrs.className,
    [
      'flex',
      'flex-col',
      'relative',
      'bg-white',
      'm-4'
    ]
  )
  attrs.style = {
    flexBasis: breakpoint.lgAndUp ? 'calc(33.333333% - 2rem)' : (breakpoint.mdAndUp ? 'calc(50% - 2rem)' : '100%'),
    borderRadius: '0 2.5rem 1.5rem 1.5rem',
    ...attrs.style
  }

  return <div
    {...attrs}>
    <Media course={course} integrationSettings={integrationSettings} />
    <Body course={course} />
    <Action
      course={course}
      integrationSettings={integrationSettings} />
  </div>
}

Card.propTypes = {
  course: PropTypes.object,
  integrationSettings: PropTypes.object
}

export default Card
